<template>
  <svg
    height="20"
    viewBox="0 0 37 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.9585 9.7759C26.2994 9.36998 25.4425 9.36998 24.7175 9.7759L19.5762 12.8879L16.0828 14.9175L10.9416 18.0296C10.2825 18.4355 9.4256 18.4355 8.70055 18.0296L4.61393 15.5941C3.9548 15.1882 3.4934 14.444 3.4934 13.6321V8.82875C3.4934 8.01691 3.88888 7.27273 4.61393 6.86681L8.63464 4.49894C9.29377 4.09302 10.1506 4.09302 10.8757 4.49894L14.8964 6.86681C15.5555 7.27273 16.0169 8.01691 16.0169 8.82875V11.9408L19.5103 9.84355V6.7315C19.5103 5.91966 19.1149 5.17548 18.3898 4.76956L10.9416 0.30444C10.2825 -0.10148 9.4256 -0.10148 8.70055 0.30444L1.12053 4.76956C0.39548 5.17548 0 5.91966 0 6.7315V15.7294C0 16.5412 0.39548 17.2854 1.12053 17.6913L8.70055 22.1564C9.35969 22.5624 10.2166 22.5624 10.9416 22.1564L16.0828 19.112L19.5762 17.0148L24.7175 13.9704C25.3766 13.5645 26.2335 13.5645 26.9585 13.9704L30.9792 16.3383C31.6384 16.7442 32.0998 17.4884 32.0998 18.3002V23.1036C32.0998 23.9154 31.7043 24.6596 30.9792 25.0655L26.9585 27.5011C26.2994 27.907 25.4425 27.907 24.7175 27.5011L20.6968 25.1332C20.0376 24.7273 19.5762 23.9831 19.5762 23.1712V20.0592L16.0828 22.1564V25.2685C16.0828 26.0803 16.4783 26.8245 17.2034 27.2304L24.7834 31.6956C25.4425 32.1015 26.2994 32.1015 27.0244 31.6956L34.6045 27.2304C35.2636 26.8245 35.725 26.0803 35.725 25.2685V16.2706C35.725 15.4588 35.3295 14.7146 34.6045 14.3087L26.9585 9.7759Z"
      fill="#7950DD"
    />
  </svg>
</template>

<script>
export default {
  name: "MATICicon",
};
</script>

<style></style>
