<template>
  <blockchain-list></blockchain-list>
</template>

<script>
import BlockchainList from "/src/components/steps/BlockchainList.vue";
export default {
  name: "Blockchain",
  components: {
    BlockchainList,
  },
};
</script>

<style></style>
