<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1692.31 2000"
    width="20"
    height="20"
  >
    <defs>
      <linearGradient
        id="a"
        x1="191.59"
        y1="1712.04"
        x2="1419.34"
        y2="662.26"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#beea2e" />
        <stop offset="1" stop-color="#52ba00" />
      </linearGradient>
    </defs>
    <path
      d="M153.85 380.09l669.68 316.74V2000l-669.68-316.74V380.09zM1167.42 0L186 348.94l663.68 318.74 978.38-350.94zm-18.1 596.29v797.38l696.83 271.49V345.89z"
      transform="translate(-153.85)"
      fill="url(#a)"
    />
  </svg>
</template>

<script>
export default {
  name: "NEOicon",
};
</script>

<style></style>
