var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"justify":"center"}},[(_vm.userMadeAChoice === false)?_c('v-card',{staticClass:"fill-height",attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" Pick your blockchain ")]),_c('v-card-subtitle',[_vm._v(" Click on the blockchain and install a wallet to hold your Cuties and coins. When ready, return to this page. ")]),_c('v-card-actions',[(!_vm.isMobile)?_c('v-row',{attrs:{"justify-space-around":"","no-gutters":""}},_vm._l((_vm.blockchains),function(blockchain){return _c('v-col',{key:blockchain.name,staticClass:"flex-wrap",attrs:{"cols":"4"}},[_c('blockchain-card',{attrs:{"blockchain":blockchain},nativeOn:{"click":function($event){_vm.chosenBlockchain = blockchain;
              _vm.userMadeAChoice = true;}}})],1)}),1):_c('v-row',[_c('v-carousel',{attrs:{"hide-delimiters":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.blockchains),function(blockchain){return _c('v-carousel-item',{key:blockchain.name},[_c('blockchain-card',{attrs:{"blockchain":blockchain},nativeOn:{"click":function($event){_vm.chosenBlockchain = blockchain;
                _vm.userMadeAChoice = true;}}})],1)}),1)],1)],1)],1):_c('v-card',{staticClass:"fill-height",attrs:{"flat":""}},[_c('v-row',{attrs:{"justify-space-around":"","no-gutters":""}},[(!_vm.isMobile)?_c('v-col',{attrs:{"cols":"6"}},[_c('blockchain-card',{attrs:{"blockchain":_vm.chosenBlockchain,"nonActive":""}})],1):_vm._e(),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":_vm.isMobile ? '12' : '6'}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('h2',[_vm._v("Welcome to "+_vm._s(_vm.chosenBlockchain.name)+" team!")]),_c('p',[_vm._v(" You chose to start your journey on "+_vm._s(_vm.chosenBlockchain.name)+", but you can add other blockchains to your profile at any time. ")]),_c('v-checkbox',{attrs:{"label":_vm.isMobile
                  ? 'I have activated ' +
                    _vm.chosenBlockchain.name +
                    ' in my BCU wallet'
                  : 'I have installed ' + _vm.chosenBlockchain.walletNameDesktop},model:{value:(_vm.walletInstalled),callback:function ($$v) {_vm.walletInstalled=$$v},expression:"walletInstalled"}})],1),_c('v-card-actions',[_c('v-btn',{staticClass:"mr-5 ml-1",on:{"click":function($event){_vm.userMadeAChoice = false;
                _vm.walletInstalled = false;}}},[_vm._v("Back")]),_c('v-spacer'),_c('v-btn',{attrs:{"disabled":!_vm.walletInstalled,"color":"primary"},on:{"click":_vm.finishBlockchainChioce}},[_vm._v("Continue")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }