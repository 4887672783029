<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 2000 2000"
    width="20"
    height="20"
  >
    <circle cx="1000" cy="1000" r="1000" fill="#0f4fe3" />
    <path
      fill="none"
      stroke="#fff"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="80"
      d="M680.39 725.61l319.58 1003.6-474.21-297.14 154.63-706.46L999.97 289v306.23l-474.21 836.84h948.9l-474.21-836.84V289l319.58 436.61 154.63 706.46-474.21 297.14 319.58-1003.6"
    />
  </svg>
</template>

<script>
export default {
  name: "EOSicon",
};
</script>

<style></style>
