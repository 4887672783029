<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 205 205"
  >
    <g id="heco-icon" transform="translate(-44.353 -43.389)">
      <rect
        id="Rectangle_36"
        data-name="Rectangle 36"
        width="205"
        height="205"
        transform="translate(44.353 43.389)"
        fill="none"
      />
      <g id="hoo" transform="translate(63.353 55.389)">
        <g id="Group_16" data-name="Group 16" transform="translate(0.233 0)">
          <path
            id="Path_169"
            data-name="Path 169"
            d="M116.174,110.462a1.785,1.785,0,0,1,.366-.166c1.365-.632,2.729-1.231,4.094-1.764V81.606a8.878,8.878,0,0,0-8.853-8.886H75.07V150A88.535,88.535,0,0,1,116.174,110.462Z"
            transform="translate(-75.07 -72.72)"
            fill="rgba(174,251,25,.8)"
          />
        </g>
        <g id="Group_17" data-name="Group 17" transform="translate(0 39.073)">
          <path
            id="Path_170"
            data-name="Path 170"
            d="M153.713,130.19H161.9a21.491,21.491,0,0,0,21.5-21.5V85.658c-4.427-.766-24.662-1.2-29.355-1.2A78.988,78.988,0,0,0,75.2,157.947c-.133,1.831-.2,3.661-.2,5.525s.067,3.694.2,5.525h0v49.125a8.849,8.849,0,0,0,8.853,8.853h36.71V163.04a32.918,32.918,0,0,1,32.949-32.85Z"
            transform="translate(-75 -84.46)"
            fill="rgba(174,251,25,.8)"
          />
        </g>
        <g
          id="Group_18"
          data-name="Group 18"
          transform="translate(120.682 104.307)"
        >
          <path
            id="Path_171"
            data-name="Path 171"
            d="M115.72,143.6a1.784,1.784,0,0,1-.366.166c-1.365.632-2.729,1.231-4.094,1.764v26.925a8.878,8.878,0,0,0,8.853,8.886H156.79V104.06A88.615,88.615,0,0,1,115.72,143.6Z"
            transform="translate(-111.26 -104.06)"
            fill="rgba(174,251,25,.8)"
          />
        </g>
        <g id="Group_19" data-name="Group 19" transform="translate(58.011)">
          <path
            id="Path_172"
            data-name="Path 172"
            d="M200.631,130.7h0V81.573a8.849,8.849,0,0,0-8.853-8.853h-36.71v63.935a32.911,32.911,0,0,1-32.916,32.85H113.93a21.491,21.491,0,0,0-21.5,21.5v23.031c4.427.766,24.662,1.2,29.355,1.2.533,0,1.032,0,1.564-.033a78.288,78.288,0,0,0,31.818-7.355l.2-.1a78.886,78.886,0,0,0,45.3-66.032c.133-1.831.2-3.661.2-5.525C200.831,134.359,200.764,132.528,200.631,130.7Z"
            transform="translate(-92.43 -72.72)"
            fill="rgba(174,251,25,.8)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "HECOicon",
};
</script>

<style></style>
